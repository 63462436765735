import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { shuffle } from "lodash";

import { motion } from "framer-motion";
import "../styles/special.scss";
import SEO from "../components/seo";
import Layout from "../components/layout";

const spring = {
  type: "spring",
  damping: 30,
  stiffness: 200
};

const Logo = () => {
  const [colors, setColors] = useState(initialColors);

  useEffect(() => {
    setTimeout(() => setColors(shuffle(colors)), 1000);
  }, [colors]);

  return (
    <ul>
      {colors.map((background, index) => (
        <motion.li
          key={background}
          layoutTransition={spring}
          style={{
            background: background + "60",
            fontSize: 80,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {emojis[index]}
        </motion.li>
      ))}
    </ul>
  );
};

const initialColors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF"];
const emojis = ["👋", "😘", "🎉", "🙌"];

export default () => (
  <Layout>
    <div className="row">
      <SEO title="Our Mission" />
      <div
        className="col-xs-12 pad-10-t"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Logo />
      </div>
      <div className="col-xs-12 pad-10">
        <h1 className="is-hero-menu is-blue">Emoji.</h1>
        <div className="line is-blue" />
        <h1 className="is-grey">
          A powerful way to display emotion. But sometimes one, two or three are
          not enough. Sometimes you need a wall of them.
        </h1>
        <Link to="/generator">
          <button className="btn is-blue-bg">
            <h1>Build a wall.</h1>
          </button>
        </Link>
      </div>
    </div>
  </Layout>
);
